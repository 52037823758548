import {noop, debounce} from "@utils/utils";

import {generateFormItem} from "./generator";

export default {
  name: "com-commonForm",
  props: {
    configs: {type: Array | Function, default: () => []},//表单配置列表
    inline: {type: Boolean, default: false},//是否为行内表单
    labelWidth: {type: String , default: '120px'},//表单标签宽带
    labelPosition: {type: String, default: "right"},//对齐方式
    initFormData: {type: Object},//默认表单数据
    col: {type: Number, default: 1},//行内表单列数
    updatedSign: {type: Boolean, default: false},
    showType:{ type:String },
    tableName:{ type:String }
  },
  data() {
    return {
      updateFormSign: false,
      formDataSettingSign: false,
      formData:{},
      configData: []
    };
  },
  watch: {
    configs(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      this.init();
      // todo resetForm
    }
  },
  render(h) {
    let formItemList = [],
      rules = {},
      colDivSign = this.col >= 1 && this.inline === false;

    this.configData.forEach(item => {
      let formItem = null;
      if (item.required && !item.disabled) {
        let message = ['select', 'search'].includes(item.type) ? `请选择${item.label}` : `请输入${item.label}`;
        let trigger = ['select', 'search'].includes(item.type) ? "change" : "blur";
        let requiredRule = {
          required: true,
          message,
          trigger
        };

        if (rules[item.key]) {
          rules[item.key].push(requiredRule);
        } else {
          rules[item.key] = [requiredRule];
        }
      }

      if (item.rules) {
        rules[item.key] = (rules[item.key] || []).concat(item.rules);
      }

      if (item.type === "empty") {
        formItem = <span>&nbsp;</span>;
      } else if (item.type === "string") {
        formItem = <span class="text-ellipsis">{this.formData[item.key]}</span>;
      } else if (item.type === "ignore") {//增加fromData属性，但不显示，方便整理
        return;
      } else if (item.type === "render" && item.render) {
        formItem = item.render(
          h,
          this,
          item,
          this.formData[item.key],
          data => {
            this.formData[item.key] = data;
          }
        );
      } else {
        formItem = generateFormItem(h, this, item);
      }

      formItem = (
        <el-form-item
          key={item.key}
          prop={item.key}
          class="el-item-w-100"
          {...{
            props: {
              ...(item.noLabel ? {"label-width": "0px"} : {})
            }
          }}
        >
          <label class="el-form-item__label custom-label" style={{left:'-'+this.labelWidth,width:this.labelWidth,textAlign:this.labelPosition}}>
            <text-ellipsis msg={item.label} width={this.labelWidth}></text-ellipsis>
           {/* <el-tooltip placement="top" content={item.label}>
              <span>{item.label}</span>
            </el-tooltip>*/}
          </label>
          {formItem}
        </el-form-item>
      );

      if (colDivSign) {
        formItem = (
          <el-col
            span={
              item.fullLine
                ? 24
                : Math.floor(24 / this.col) * (item.col || 1)
            }
            class='p-h16'
          >
            {formItem}
          </el-col>
        );
      }

      formItemList.push(formItem);
    });

    if (colDivSign) {
      formItemList = <el-row type="flex" justify="start">{formItemList}</el-row>;
    }

    return (
      <el-form
        {...{
          class: "component-commonForm", // ofxh
          on: {
            input: noop
          },
          props: {
            model: this.formData,
            rules: rules,
            inline: this.inline,
            "label-position": this.labelPosition,
            "label-width": this.labelWidth,
            "validate-on-rule-change": false
          },
          ref: "form",
          key: "form"
        }}
      >
        {formItemList}
        {this.$slots.inline}
      </el-form>
    );
  },
  mounted() {
    this.updatedSign && this.$emit("common-form-updated", this.formData);
    this.init();
  },
  methods: {
    init(){
      if (Array.isArray(this.configs)) {
        this.formData = this.initFormData || this.getInitFormData(this.configs);
        this.$nextTick(() => {
          this.loadAsyncData();
        });
        this.configData =  [...this.configs]
        return ;
      }

      this.formDataSettingSign = true;

      this.configs()
        .then(result => {
          this.formData = result.formData;
          this.configData = result.configData;
          this.formDataSettingSign = false;
          this.$emit("setFormDataEnd");

          this.debounceUpdateFunction = debounce(
            this.updateFunctionalConfig,
            300
          );

          this.$nextTick(() => {
            this.updateUnWatchHandle = this.$watch(
              "formData",
              this.debounceUpdateFunction,
              {
                deep: true
              }
            );
          });
        })
        .catch(err => {
          console.log("form config init function err", err);

          this.formDataSettingSign = false;
          this.$emit("setFormDataEnd");
        });
    },
    getInitFormData(configs) {
      configs = configs || this.configData;

      let formData = {};

      configs.forEach(config => {
        let defaultData = null;
        //处理数据
        if (typeof config.disabled === "function") {
          config.disabled = config.disabled();
        }
        if (typeof config.default === "function") {
          defaultData = config.default();
          // eslint-disable-next-line no-undefined
        } else if (config.default !== undefined) {
          defaultData = config.default;
        }
        //过滤formdata
        !config.filter && (formData[config.key] = defaultData);
      });
      return formData;
    },
    async loadAsyncData() {
      let configs = this.configData,
        processList = configs.map((config, configIndex) => {
          return (async () => {
            if (
              config.type === "select" &&
              typeof config.options === "function" ||
              config.type === "multiselect" &&
              typeof config.options === "function"
            ) {
              let options = await config.options();
              this.configData[configIndex].options = options;
            }
          })();
        });
      await Promise.all(processList);
      this.$forceUpdate();
      this.$emit('formMounted')
    },
    updateFunctionalConfig() {
      if (typeof this.configs !== "function") {
        return;
      }

      if (this.updateUnWatchHandle) {
        this.updateUnWatchHandle();
        this.updateUnWatchHandle = null;
      }

      // if (this.updateFormSign) {
      //     this.updateFormSign = false;
      //     return;
      // }

      this.formDataSettingSign = true;

      this.configs({
        formData: this.formData,
        configData: this.configData
      })
        .then(result => {
          if (!result.notChange) {
            this.formData = result.formData;
            this.configData = result.configData;
            this.updateFormSign = true;
          }
          this.$nextTick(() => {
            this.updateUnWatchHandle = this.$watch(
              "formData",
              this.debounceUpdateFunction,
              {
                deep: true
              }
            );

            this.$nextTick(() => {
              console.log("watching out formData", this.formData);
            });

            this.formDataSettingSign = false;
            this.$emit("setFormDataEnd");
          });
        })
        .catch(err => {
          console.log("form config function err", err);

          this.updateUnWatchHandle = this.$watch(
            "formData",
            this.debounceUpdateFunction,
            {
              deep: true
            }
          );

          this.formDataSettingSign = false;
          this.$emit("setFormDataEnd");
        });
    },
    setFormData(formData) {
      if (this.formDataSettingSign) {
        this.$once("setFormDataEnd", () => {
          this.formData = {...this.formData, ...formData};
        });
        return;
      }
      this.formData = {...this.formData, ...formData};
    },
    validate() {
      return this.$refs.form.validate(...arguments);
    },
    resetFields() {
      this.$refs.form.resetFields();

      this.formData = this.getInitFormData();
    }
  },
  destroyed() {
    if (this.updateUnWatchHandle) {
      this.updateUnWatchHandle();
    }
  }
};
