const formTypeGeneratorMap = {
  select: {
    generate(h, item) {
      return (
        <el-select
          class="w-100"
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled={item.disabled}
          filterable={true}
          clearable={true}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
        >
          {(Array.isArray(item.options) ? item.options : []).map(
            option => {
              return (
                <el-option
                  key={option.key}
                  label={option.label}
                  value={option.key}
                ></el-option>
              );
            }
          )}
        </el-select>
      );
    }
  },
  textarea: {
    generate(h, item) {
      return (
        <el-input
          type="textarea"
          rows={item.rows || 1}
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled={item.disabled}
          maxlength={item.maxlength}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
        ></el-input>
      );
    }
  },
  input: {
    generate(h, item) {
      return (
        <el-input
          class={ item.defaultUpper&&"inputUpCase" }
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled={item.disabled}
          maxlength={item.maxlength}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
          {...{nativeOn: {...(item.nativeOn || {})}}}
          {...{attrs: {...(item.attrs || {})}}}
        ></el-input>
      );
    }
  },
  radio: {
    generate(h, item) {
      return (
        <el-radio-group
          vModel={this.formData[item.key]}
          disabled={item.disabled}
        >
          {(Array.isArray(item.options) ? item.options : []).map(
            option => {
              return (
                <el-radio
                  label={option.key}
                  key={option.key}
                  disabled={option.disabled}
                >
                  {option.label}
                </el-radio>
              );
            }
          )}
        </el-radio-group>
      );
    }
  },
  date: {
    generate(h, item) {
      return (
        <el-date-picker
          class="w-100"
          vModel={this.formData[item.key]}
          type="date"
          placeholder={item.placeholder}
          disabled={item.disabled}
          vDateCount={item.key}
          {...{props: {...(item.props || {})}}}
        ></el-date-picker>
      );
    }
  },
  time: {
    generate(h, item) {
      return (
        <el-time-picker
          class="w-100"
          picker-options={item.pickerOptioins || {}}
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled={item.disabled}
          {...{props: {...(item.props || {})}}}
        ></el-time-picker>
      );
    }
  },
  datetime: {
    generate(h, item) {
      return (
        <el-date-picker
          class="w-100"
          type="datetime"
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled={item.disabled}
        ></el-date-picker>
      );
    }
  },
  daterange: {
    generate(h, item) {
      return (
        <el-date-picker
          class="w-100"
          style={{width: "100%"}}
          vModel={this.formData[item.key]}
          unlink-panels={true}
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          valueFormat="yyyy-MM-dd HH:mm:ss"
          disabled={item.disabled}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
        ></el-date-picker>
      );
    }
  },
  multiselect: {
    generate(h, item) {
      return (
        <el-select
          class="w-100"
          vModel={this.formData[item.key]}
          multiple
          filterable
          placeholder={item.placeholder}
          disabled={item.disabled}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
        >
          {(Array.isArray(item.options) ? item.options : []).map(
            option => {
              return (
                <el-option
                  key={option.key}
                  label={option.label}
                  value={option.key}
                ></el-option>
              );
            }
          )}
        </el-select>
      );
    }
  },
  checkbox: {
    generate(h, item) {
      return (
        <el-checkbox-group
          vModel={this.formData[item.key]}
          disabled={item.disabled}
        >
          {(Array.isArray(item.options) ? item.options : []).map(
            option => {
              return (
                <el-checkbox
                  label={option.label}
                  key={option.key}
                  value={option.key}
                  disabled={option.disabled}
                ></el-checkbox>
              );
            }
          )}
        </el-checkbox-group>
      );
    }
  },
  cascader: {
    generate(h, item) {
      return (
        <el-cascader
          class="w-100"
          options={item.options}
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled={item.disabled}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
        ></el-cascader>
      );
    }
  },
  switch: {
    generate(h, item) {
      return [
        <el-switch
          vModel={this.formData[item.key]}
          active-color="#13ce66"
          disabled={item.disabled}
        />,
        item.options ? (
          <span style="padding: 0 0 0 10px;font-size: 13px;vertical-align: bottom;">
                        {this.formData[item.key]
                          ? item.options[1]
                          : item.options[0]}
                    </span>
        ) : null
      ];
    }
  },
  stringrange: {
    generate(h, item) {
      return (
        <com-stringRange
          vModel={this.formData[item.key]}
          {...{props: {...item.options}}}
          disabled={item.disabled}
          maxlength={item.maxlength}
        ></com-stringRange>
      );
    }
  },
  tableTransfer: {
    generate(h, item) {
      return (
        <com-tableTransfer
          vModel={this.formData[item.key]}
          {...{props: {...item.options}}}
          disabled={item.disabled}
        ></com-tableTransfer>
      );
    }
  },
  search: {
    generate(h, item) {
      return (
        <el-autocomplete
          vModel={this.formData[item.key]}
          fetchSuggestions={item.fetchHandle}
          placeholder={item.placeholder}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
        ></el-autocomplete>
      );
    }
  },
  text: {
    generate(h, item) {
      return (
        <el-input
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          disabled
          maxlength={item.maxlength}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
          {...{attrs: {...(item.attrs || {})}}}
        ></el-input>
      );
    }
  },
  numrange: {
    generate(h, item) {
      return (
        <com-numRange
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          showType={this.showType}
          maxlength={item.maxlength}
          disabled={item.disabled}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
          {...{attrs: {...(item.attrs || {})}}}
        ></com-numRange>
      );
    }
  },
  magnifier: {
    generate(h, item) {
      return (
        <com-magnifier
          class={ item.defaultUpper&&"inputUpCase" }
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          showType={this.showType}
          disabled={item.disabled}
          itemKey={item.key}
          itemLabel={item.label}
          tableName={item.tableName}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
          {...{attrs: {...(item.attrs || {})}}}
        ></com-magnifier>
      );
    }
  },
  autoComplete: {
    generate(h, item) {
      return (
        <com-autocomplete
          class={ item.defaultUpper&&"inputUpCase" }
          vModel={this.formData[item.key]}
          placeholder={item.placeholder}
          showType={this.showType}
          disabled={item.disabled}
          itemKey={item.key}
          tableName={item.tableName}
          {...{props: {...(item.props || {})}}}
          {...{on: {...(item.on || {})}}}
          {...{attrs: {...(item.attrs || {})}}}
        ></com-autocomplete>
      );
    }
  },
};

export function generateFormItem(h, vm, item) {
  let formItem = null,
    itemType = item.type || "input",
    formTypeGenerator = formTypeGeneratorMap[itemType];

  if (!formTypeGenerator) {
    return null;
  }

  formItem = formTypeGenerator.generate.call(vm, h, item);

  return formItem;
}
